import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { TWStyles } from "../../artifacts/twlit.js";
import { SwipeMixin } from "./SwipeMixin.js";

import './new-writing.js';

@customElement("view-home")
class ViewHome extends SwipeMixin(LitElement) {

	static styles = [TWStyles, css`
		.p-gradient {
			background: linear-gradient(90deg, #ff9a9e 0%, #fad0c4 100%);
		}
		.fuzzy {
			filter: blur(2px);
		}
		pre {
			display: unset;
			font-family: auto;
			unicode-bidi: unset;
			white-space: pre;
		}
		.h-available {
			height: -webkit-fill-available;
		}
	`];

	@property({type:Object}) user: any;
	@property({type:Array}) writings: WriteryListItem[] = [];

	userCompose(writing = null) {
		const newWritingComponent = this.shadowRoot?.querySelector('new-writing');
		if (newWritingComponent) {
			(newWritingComponent as any).open(writing);
		}
	}

	touchFunctions = {
		right: 'openMenu',
	}
	openMenu() {
		this.dispatchEvent(new CustomEvent('menu', { bubbles: true, composed: true, detail: { open: true } }));
	}
	handlePostSave() {
		this.renderRoot.querySelector('div').scrollTo(0, 0);
	}

  	render() {
    return html`
		
		<div class="relative w-full h-full p-2 grid grid-cols-[repeat(auto-fill,16rem)] gap-2 
			items-center justify-items-center justify-evenly overflow-auto"
			@touchstart=${(e: PointerEvent | TouchEvent) => {
				//@ts-ignore
				this.__handleTouchStart(e)
			}}
			@touchmove=${(e: PointerEvent | TouchEvent) => {
				//@ts-ignore
				this.__handleTouchMove(e, this.touchFunctions)
			}}>
			<div 
				class="noswipe bg-white shadow-lg rounded-lg w-64 h-80 cursor-pointer relative" 
				@click=${() => this.userCompose(null)}>
				
				<svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full absolute scale-50 overflow-hidden" viewBox="0 -960 960 960">
					<path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
					<defs>
						<linearGradient id="writery-gradient" x1="0%" y1="100%" x2="100%" y2="0%">
							<stop offset="0%" style="stop-color:rgb(255,154,158);stop-opacity:1" />
							<stop offset="100%" style="stop-color:rgb(250,208,196);stop-opacity:1" />
						</linearGradient>
					</defs>
					<style type="text/css">
						svg{fill:url(#writery-gradient)}
					</style>
				</svg>

				<div class="p-4 fuzzy absolute">
					<div class="text-lg font-bold">
						Blank Page
					</div>
					<div class="text-sm text-gray-600">
						Start anew
					</div>
				</div>
			</div>
			${this.writings.map((writing) => html`
				<div
					@click=${() => this.userCompose(writing)} 
					class="noswipe overflow-hidden p-4 bg-white shadow-lg rounded-lg w-64 h-80 cursor-pointer relative">
					<div class="noswipe text-lg font-bold">${writing.title}</div>
					<pre class="noswipe h-available text-sm text-gray-600 absolute mb-4 h-full overflow-hidden pt-2">${writing.content}</pre>
				</div>
			`)}
		</div>

		<div class="w-full absolute bottom-0">
			<new-writing .user=${this.user} @saved=${this.handlePostSave}></new-writing>
		</div>
	`}
}

declare global {
  interface HTMLElementTagNameMap {
    "view-home": ViewHome;
  }
}

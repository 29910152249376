
export const Region = 'us-east-2';
export const ClientId = 'bena23g1ahh81aeru1s0himfo';
export const UserPoolId = 'us-east-2_ZDXyQSYKV';
export const IdentityPoolId = 'us-east-2:e5898d05-ee56-4e21-a3aa-73832e2bd9fb';
export const cogIdConfig = { region: Region };


export class User {
	username: string;
	auth: string;
	region: string;
	userPoolId: string;
	identityPoolId: string;
	clientId: string;
    id: string;
    session: any;
	constructor(token, session) {

		console.log('User construct: ', token)
		const {
			sub,
		} = token;
		this.username = token.email;
        this.session = session;
        // RefreshToken
        // AccessToken
        // IdToken
        // ExpiresIn

		this.region = Region;
		this.userPoolId = UserPoolId;
		this.clientId = ClientId;
		this.identityPoolId = IdentityPoolId;
        this.id = sub;
		localStorage.setItem('uid', this.id);
	}
	get logins() {
		return {
			[`cognito-idp.${this.region}.amazonaws.com/${this.userPoolId}`]: this.session?.IdToken,
		};
	}
}
import { CSSResult, html } from "lit";
import { customElement, property, state } from 'lit/decorators.js';// @ts-ignore
import { TWStyles } from "../../../artifacts/twlit.js";
import { forgotPassword, } from './cognito.js';// @ts-ignore
import { errorFirstPromise, AuthExtension } from './auth-utils.js';

@customElement('aws-auth-forgot')
export class AwsAuthForgot extends AuthExtension {

	static styles: CSSResult[] = [TWStyles as CSSResult];

	@property() email: string = '';
	@state() pass: string = '';

  async sendForgotCode(e: Event) {
    e.preventDefault();
    if(!this.email) return this.toast({ variant: 'error', title: 'Forgot Password Error', message: 'Email is required' }); 

    const call = forgotPassword({ username: this.email });
    const [err, res] = await errorFirstPromise(call);

    if(err) {
      console.error(err);
      this.toast({ variant: 'error', title: 'Forgot Password Error', message: err.message });
      return;
    }

    console.log(res);

    this.toast({ variant: 'success', title: 'Forgot Password', message: 'Code sent to '+this.email });

    this.show('forgot-verify');
  }
  
	render() {
		return html`

      <form class="space-y-6" @submit=${this.sendForgotCode}>
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-black  dark:text-white">Email</label>
          <div class="mt-2">
            <input @change=${(e: Event) => this.email = (e.target as HTMLInputElement).value} 
              required
              value=${this.email} 
              id="email" 
              name="email" 
              type="text" 
              class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm leading-6"
              autocomplete="username"/>
          </div>
        </div>
        <div>
          <button class="bg-primary flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
            Send Code
          </button>
        </div>
  
        <p class="mt-10 text-center text-sm text-accent">
          <a @click=${() => this.show('login')} class=" cursor-pointer font-semibold leading-6 color-primary">
            Back
          </a>
        </p>
      </form>
    `;
  }
}
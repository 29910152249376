import { CSSResult, LitElement, css, html } from "lit";

export function errorFirstPromise(promise) {
    return promise
        .then(data => [null, data])
        .catch(err => [err, null]);
}

export class AuthExtension extends LitElement {
    toast(detail){
        this.dispatchEvent(new CustomEvent('toast', {
        detail,
        bubbles: true,
        composed: true,
        }));
    }
    show(name: string) {
        this.dispatchEvent(new CustomEvent('show', {
        detail: name,
        bubbles: true,
        composed: true,
        }));
    }
    setEmail(email: string) {
        this.dispatchEvent(new CustomEvent('email', {
            detail: email,
            bubbles: true,
            composed: true,
        }));
    }
}

export function isEmail(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
import { CSSResult, html } from "lit";
import { customElement, property, state } from 'lit/decorators.js';// @ts-ignore
import { TWStyles } from "../../../artifacts/twlit.js";
import { signUp, } from './cognito.js';// @ts-ignore
import { errorFirstPromise, AuthExtension, isEmail } from './auth-utils.js';

@customElement('aws-auth-sign')
export class AwsAuthSign extends AuthExtension {
	static styles: CSSResult[] = [TWStyles as CSSResult];

	@property() email: string = '';
	@state() name: string = '';
	@state() pass: string = '';
	@state() passAgain: string = '';

  get creds() {
    return {
      Username: this.email,
      Password: this.pass,
      UserAttributes: [ // AttributeListType
        { // AttributeType
          Name: "name", // required
          Value: this.name, // required
        },
      ],
    }
  }

  async submit(e: Event) {
    e.preventDefault();

    console.log({
      where: 'submit sign up',
      email: this.email,
      pass: this.pass,
      passAgain: this.passAgain,
      creds: this.creds,
    })

    if(!this.email || !this.pass || !this.passAgain) return this.toast({
      message: 'Needs an email, password and password confirmation',
      variant: 'error',
      title: 'Sign Up Error',
    });

    if(!isEmail(this.email)) return this.toast({
      message: 'Email is not valid',
      variant: 'error',
      title: 'Sign Up Error',
    });

    if( this.pass !== this.passAgain) {
      console.warn('Passwords do not match');
      return this.dispatchEvent(new CustomEvent('toast', {
        bubbles: true,
        composed: true,
        detail: {
          variant: 'error',
          title: 'Sign Up Error',
          message: 'Passwords do not match'
        }
      }));
    }

    if(this.pass.length < 8) {
      console.warn('Password needs to be at least 8 characters');
      return this.dispatchEvent(new CustomEvent('toast', {
        bubbles: true,
        composed: true,
        detail: {
          variant: 'error',
          title: 'Sign Up Error',
          message: 'Password must be at least 8 characters'
        }
      }));
    }

    const call = signUp(this.creds);

    const [err, res] = await errorFirstPromise(call);
    
    if(err) {
      console.error(err);
      return this.dispatchEvent(new CustomEvent('toast', {
        bubbles: true,
        composed: true,
        detail: {
          variant: 'error',
          title: 'Sign Up Error',
          message: err.message
        }
      }));
    }

    console.log(res);

    this.toast({ variant: 'success', title: 'Sign Up', message: 'Please verify your email with the code sent to '+this.email });
    this.setEmail(this.email);
    this.show('verify');

    res.username = this.email;

    return this.dispatchEvent(new CustomEvent('auth', {
      bubbles: true,
      composed: true,
      detail: res
    }));
  }

	render() {
		return html`
      <form class="space-y-6" @submit=${this.submit}>
        <div>
          <label for="name" class="block text-sm font-medium leading-6 text-black dark:text-white">
            Pen Name
          </label>
          <div class="mt-2">
            <input @change=${(e: Event) => this.name = (e.target as HTMLInputElement).value} value=${this.name} id="name" name="name" type="text" required 
              class="block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-black dark:text-white">Email address</label>
          <div class="mt-2">
            <input 
              @change=${(e: Event) => this.email = (e.target as HTMLInputElement).value} value=${this.email} 
              id="email" 
              name="email" 
              type="text" 
              required 
              class="block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              autocomplete="username"/>
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-black dark:text-white">Password</label>
          </div>
          <div class="mt-2">
            <input @change=${(e: Event) => this.pass = (e.target as HTMLInputElement).value} 
              id="password" 
              name="password" 
              type="password" 
              autocomplete="current-password" 
              required 
              class="block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm leading-6"
            />
          </div>
        </div>
        <div>
          <div class="flex items-center justify-between">
            <label for="password-again" class="block text-sm font-medium leading-6 text-black dark:text-white">
              Re-enter Password
            </label>
          </div>
          <div class="mt-2">
            <input @change=${(e: Event) => this.passAgain = (e.target as HTMLInputElement).value} 
              id="password-again" 
              name="password-again" 
              type="password" 
              autocomplete="current-password" 
              required 
              class="block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
          </div>
        </div>
        
        <div>
          <button class="bg-primary text-white flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
            Sign Up
          </button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-black dark:text-white">
        Have a code?&nbsp;
        <a @click=${() => this.show('code-verify')} class="cursor-pointer font-semibold leading-6 color-primary">
          Confirm
        </a>
      </p>

      <p class="mt-2 text-center text-sm text-black  dark:text-white">
        Already a member?&nbsp;
        <a @click=${() => this.show('login')} class=" cursor-pointer font-semibold leading-6 color-primary">
          Sign in
        </a>
      </p>
    `
  }
}
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';// @ts-ignore
import { AuthExtension, } from './auth-utils.js';

import './aws-auth-login.js';
import './aws-auth-sign.js';
import './aws-auth-forgot.js';
import './aws-auth-forgot-confirm.js';
import './aws-auth-code-confirm.js';

@customElement('aws-auth')
export class AwsAuth extends AuthExtension {

	@state() needsToVerify: boolean = false;
	@state() needsToResetPassword: boolean = false;
	@state() needsToVerifyPassword: boolean = false;
	@state() needsToVerifyCode: boolean = false;
	@state() needsToSignUp: boolean = false;

	@state() email: string = '';
	@state() confirmationCode: string = '';

  get html(){
    if(this.needsToVerify) return html`<aws-auth-code-confirm @show=${this.route} email=${this.email}></aws-auth-code-confirm>`;
    if(this.needsToResetPassword) return html`<aws-auth-forgot @show=${this.route} email=${this.email}></aws-auth-forgot>`;
    if(this.needsToVerifyPassword) return html`<aws-auth-forgot-confirm @show=${this.route} email=${this.email}></aws-auth-forgot-confirm>`;
    if(this.needsToVerifyCode) return html`<aws-auth-code-confirm @show=${this.route} email=${this.email}></aws-auth-code-confirm>`;
    if(this.needsToSignUp) return html`<aws-auth-sign @show=${this.route} email=${this.email}></aws-auth-sign>`;
    return html`<aws-auth-login @show=${this.route} email=${this.email}></aws-auth-login>`;
  }

  // default to login
  route(e) {
    const {detail} = e;
    this.needsToVerify = detail === 'has-code';
    this.needsToResetPassword = detail === 'forgot';
    this.needsToVerifyPassword = detail === 'forgot-verify';
    this.needsToVerifyCode = detail === 'code-verify';
    this.needsToSignUp = detail === 'sign-up';
  }

	render() {
    return this.html
  }
}
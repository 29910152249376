import { CSSResult, css, html } from "lit";
import { customElement, property, state } from 'lit/decorators.js';// @ts-ignore
import { TWStyles } from "../../../artifacts/twlit.js";
import { verifyEmail, } from './cognito.js';// @ts-ignore
import { errorFirstPromise, AuthExtension, } from './auth-utils.js';

@customElement('aws-auth-code-confirm')
export class AwsAuthCodeConfirm extends AuthExtension {

	static styles: CSSResult[] = [TWStyles as CSSResult];

	@property() email: string = '';
	@state() pass: string = '';
	@state() confirmationCode: string = '';

  async confirmCode(e: Event) {
    e.preventDefault();
    if(!this.confirmationCode) return;

    const call = verifyEmail({
      username: this.email,
      code: this.confirmationCode
    });
    const [err, res] = await errorFirstPromise(call);

    if(err) {
      console.error(err);
      this.dispatchEvent(new CustomEvent('toast', {
        bubbles: true,
        composed: true,
        detail: {
          variant: 'error',
          title: 'Verification Error',
          message: err.message
        }
      }));
      return;
    }

    console.log(res);
    
    this.toast({
      variant: 'success',
      title: 'Verification',
      message: 'Email verified'
    });

    this.show('login');
  }
  
	render() {
		return html`
      <form class="space-y-6" @submit=${this.confirmCode}>

        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-black dark:text-white dark:text-b">Email</label>
          <div class="mt-2">
            <input 
              @change=${(e: Event) => this.email = (e.target as HTMLInputElement).value} 
              value=${this.email} 
              id="email" 
              name="email" 
              type="text" 
              required 
              class="block w-full rounded-md border-0 text-black  shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm leading-6"
              autocomplete="username"/>
          </div>
        </div>

        <div>
          <label for="code" class="block text-sm font-medium leading-6 text-black  dark:text-white">Code</label>
          <div class="mt-2">
            <input @change=${(e: Event) => this.confirmationCode = (e.target as HTMLInputElement).value} id="code" name="code" type="text" required class="block w-full rounded-md border-0 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm leading-6">
          </div>
        </div>

        <div>
          <button class="bg-primary text-white flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
            Confirm
          </button>
        </div>

        <p class="mt-10 text-center text-sm text-accent">
          <a @click=${() => this.show('login')} class=" cursor-pointer font-semibold leading-6 color-primary">
            Back
          </a>
        </p>
      </form>
    `;
  }
}
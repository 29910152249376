import { openDB, deleteDB } from 'idb';

const DB_NAME = 'writery';
const VERSION = 1;

const DB_CONFIG = {
	upgrade(db) {

		if (!db.objectStoreNames.contains('writings')) {
			// Create a store of objects
			db.createObjectStore('writings', {
				// The 'id' property of the object will be the key.
				keyPath: 'id',
				autoIncrement: false,
			});
		}
		if (!db.objectStoreNames.contains('user-writings')) {
			// Create a store of objects
			db.createObjectStore('user-writings', {
				// The 'id' property of the object will be the key.
				keyPath: 'id',
				autoIncrement: false,
			});
		}
		if (!db.objectStoreNames.contains('user')) {
			// Create a store of objects
			db.createObjectStore('user', {
				// The 'id' property of the object will be the key.
				keyPath: 'id',
				autoIncrement: false,
			});
		}
	}
}

const DB_ARGS: [string, number, object] = [DB_NAME, VERSION, DB_CONFIG];

export const getDB = () => {
	return openDB(...DB_ARGS);
}

export function getUser(id: string){
	return getItemById('user', id);
}
export function setUser(user: any){
	console.log('setUser: ', user)
	return setItem('user', user);
}

/* export async function createStore(store) {
	return openDB(DB_NAME, 1, {
		upgrade(db) {
			// Create a store of objects
			db.createObjectStore(store, {
				// The 'id' property of the object will be the key.
				keyPath: 'id',
				autoIncrement: true,
			});
		},
	});
} */

/**
 * Get all items from the database store
 * @param {String} store name of database store to get all from
 * @returns {Promise} resolves  array of items
 */
export async function getItems(store) {
	const records = await (await openDB(...DB_ARGS)).getAll(store);
	console.log('getItems', records);
	return records;
}
/**
 * Get item by id
 * @param {String} store 
 * @param {String} uid 
 * @returns {Promise<Object>} single item from store
 */
export async function getItemById(store, uid) {
	const DB = getDB();
	return (await DB).get(store, uid);
}
/**
 * Retrieves all values in an index that match the query.
 * @param {String} store name of database store to get all from
 * @param {String} indexName Name of the index within the store
 * @returns {Promise} resolves  array of items
 */
export async function getItemsByIndex(store, indexName) {
	const DB = getDB();
	return (await DB).getAllFromIndex(store, indexName);
}

/**
 * Delete item by id
 * @param {String} store 
 * @param {String} uid 
 * @returns {Promise<Object>} single item from store
 */
export async function deleteItemById(store, uid) {
	const DB = getDB();
	return (await DB).delete(store, uid);
}

/**
 * Set item (item/Object must have id)
 * @param {String} store name of store
 * @param {Object} item object to store
 * @returns {Promise<Object>} single item from store
 */
export async function setItem(store, item) {
	const DB = getDB();
	return (await DB).put(store, item);
}

/**
 * Set items (Array of items must have id)
 * @param {String} store name of store
 * @param {Array} items Array of items to store
 * @returns {Promise<Object>} single item from store
 */
export async function setItems(store, items) {
	const res = [];
	for (const item of items) {
		res.push((await setItem(store, item)));
	}
	return res;
}


/**
 * Get all keys/ids from a database store
 * @param {String} store name of database store to get all keys from
 * @returns {Promise} resolves  array of keys
 */
export async function getKeys(store) {
	const DB = getDB();
	return (await DB).getAllKeys(store);
}

/**
 * Ensure the database store exists
 * @param {String} store name of database store to get all from
 * @returns {Promise} resolves  array of items
 */
export async function checkBaseExists(store) {
	return (await window.indexedDB.databases()).map(db => db.name).includes(store);
} // @param {Boolean} create Optional; create the store if it doesn't exist

/**
 * Get array of items via keys
 * @param {String} store name of database store to get all from
 * @param {Array} keys array of keys to match
 * @returns {Promise} resolves an array of items
 */
export async function getItemsByKeys(store, keys) {
	
	const DB = getDB();
	let cursor = await (await DB).transaction(store).store.openCursor();
	let items = []

	while (cursor) {
		if(keys.includes(cursor.key)){
			items = [...items, cursor.value]
		}
		cursor = await cursor.continue();
	}

	return items;
}

/**
 * Clear a store's data
 * @param {String} store name of database store to get all from
 * @returns {Promise} resolves  array of items
 */
export async function clearStore(store) {
	const DB = getDB();
	return (await DB).clear(store);
}

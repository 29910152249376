import { CSSResult, LitElement, css, html } from "lit";
import { customElement, property, state } from 'lit/decorators.js';// @ts-ignore
import { TWStyles } from "../../artifacts/twlit.js";

import './aws-auth/aws-auth.js';

@customElement('app-registration')
export class AppRegistration extends LitElement {

	static styles: CSSResult[] = [TWStyles as CSSResult];

	render() {
		return html`
      <section id="section" class="w-full bg-transparent pt-10 flex justify-center">
        <div class="bg-white dark:bg-slate-800 rounded px-8 py-6 ">
          <div class="flex flex-col" >
            <div class="mx-auto w-full max-w-sm justify-center">
              <img class="mx-auto h-12 w-auto" src="resources/writery.png" alt="Writery">
              <h2 class="font-serif text-center mt-10 text-2xl leading-9 tracking-tight text-black dark:text-white">
                Welcome to Writery
              </h2>
            </div>

            <div class="mt-10 mx-auto w-full max-w-sm">
              <aws-auth></aws-auth>
            </div>
          </div>
        </div>
      </section>
    `;
  }
}
import { getUserListItems } from "./../util/db.js";
import { getItems, setItems } from "./idb.js";
import { getDB } from "./idb.js";
import { saveUserListItem } from "../util/db.js";
import { errorFirstPromise } from "../util/util.js";
import { setItem, getItemById } from "../util/idb.js";


export async function getWriteryListItems(user: User) {
	try {
		//@ts-ignore
		const items = await getUserListItems(user);

		setItems("writings", items);

		return items;

	} catch (e) {
		console.warn(e);
		return getItems("writings");
	}
}

export async function saveWriteryListItem(user: User, item: WriteryListItem) {
	
	const remote = saveUserListItem(user, item);
	const [error, result] = await errorFirstPromise(remote);
	console.log(error, result)
	
	if(error) throw error;
	
	const localRes = await setItem('user-writings', item);
	//console.log(localRes)
	const localTest = await getItemById('user-writings', item.id);
	//console.log({localTest})
	//const [locErr, locRes] = await errorFirstPromise(local);
	//console.log(locErr, locRes)
	//if(locErr) throw locErr;
	return result;
}


function stringArrayToArray(stringArr) {
	console.log("stringArrayToArray", stringArr);
	return stringArr
		.replace("[", "")
		.replace("]", "")
		.replace(/"/g, "")
		.trim()
		.split(",")
		.filter((x) => x)
		.map((x) => x.trim().replace(/\n/g, ""));
}

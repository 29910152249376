import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { TWStyles } from "../../artifacts/twlit.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
import { version } from '../util/util.js';
import { SwipeMixin } from "./SwipeMixin.js";

@customElement("app-layout")
class AppLayout extends SwipeMixin(LitElement) {
	static styles = [TWStyles, css`
		div > svg {
			fill: currentColor;
		}
		.p-gradient {
			background: linear-gradient(90deg, #ff9a9e 0%, #fad0c4 100%);
		}
		.text-gradient {
			background: linear-gradient(90deg, #ff9a9e 0%, #fad0c4 100%);
			-webkit-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	`];

	@property({type:Array}) navItems: NavItem[] = [];
	@property() view: string = "";
	@state() isActive: boolean = false;

	navItemClick: (e: PointerEvent, item: NavItem) => void = () => {
		//this._router(item);
		//applyAlter(e);
	};

	_router(item: NavItem) {
		console.log("App: _router: ", item);

		if (!item?.name) return;

		const router = this.renderRoot.querySelector("div#router");

		const existing = router?.querySelector(item.name);

		if (existing) {
			console.log("App: existing view", existing);
			this.hideAllViews(router);
			existing.classList.remove("opacity-0", "hidden");
			existing.classList.add("opacity-100");
		} else {
			console.log("App: new view", item);
			const classes = "opacity-100 transition-opacity ease-linear duration-300".split(" ");

			const CustomElement = window.customElements.get(item.name);

			if (!CustomElement) return console.warn("CustomElement not found");

			const view: HTMLElement = new CustomElement();

			view?.classList.add(...classes);

			this.hideAllViews(router);

			router?.append(view);
		}

		localStorage.setItem("last-item", JSON.stringify(item));
	}

	hideAllViews(parentElement) {
		parentElement.childNodes.forEach((child) => {
			if (child.nodeType === Node.ELEMENT_NODE) {
				(child as HTMLElement).classList.remove("opacity-100");
				(child as HTMLElement).classList.add("opacity-0", "hidden");
				//setTimeout(() => (child as HTMLElement).classList.add('hidden'), 300);
			}
		});
	}

	toggleActive() {
		const parent = this.renderRoot.querySelector('div[name="data-parent"]') as HTMLElement;
		this.isActive = !this.isActive;
		parent.dataset.active = this.isActive ?  "true" : "false";
	}

	touchFunctions = {
		left: 'toggleActive',
	}
	toggleIfNeed(options: any) {
		console.log('openMenu>toggleIfNeed', options);
		const {open} = options;
		if(open === true && !this.isActive) this.toggleActive();
		if(open === false && this.isActive) this.toggleActive();
	}
	
  	render() {
	return html`
<!-- Mobile: Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
<div
	name="data-parent"
	data-active="false"
	class='relative z-40 lg:hidden group/root'
	role="dialog"
	aria-modal="true"
	@touchstart=${e => {
		//@ts-ignore
		this.__handleTouchStart(e)
	}}
	@touchmove=${e => {
		//@ts-ignore
		this.__handleTouchMove(e, this.touchFunctions)
	}}>
	<!--
		Off-canvas menu backdrop, show/hide based on off-canvas menu state.

		Entering: "transition-opacity ease-linear duration-300"
		From: "opacity-0"
		To: "opacity-100"
		Leaving: "transition-opacity ease-linear duration-300"
		From: "opacity-100"
		To: "opacity-0"
			data-alter="opacity-0;opacity-100"
		
		@click=${() => { if(this.isActive) this.toggleActive();}}
	-->
<div
	name="mobile-backdrop"
	class="opacity-0 group-data-[active=true]/root:opacity-100 group-data-[active=false]/root:pointer-events-none
		fixed inset-0 bg-slate-900/80 transition-opacity ease-linear duration-300">
	
</div>

<div class="fixed inset-0 flex pointer-events-none">
	<!--
	Off-canvas menu, show/hide based on off-canvas menu state.

	Entering: "transition ease-in-out duration-300 transform"
		From: "-translate-x-full"
		To: "translate-x-0"
	Leaving: "transition ease-in-out duration-300 transform"
		From: "translate-x-0"
		To: "-translate-x-full"
	-->
	<div class="-translate-x-full group-data-[active=true]/root:translate-x-0
			 pointer-events-none relative mr-16 flex w-full max-w-xs
			 flex-1 transition ease-in-out duration-300 transform">
		<!--
			Close button, show/hide based on off-canvas menu state.

			Entering: "ease-in-out duration-300"
			From: "opacity-0"
			To: "opacity-100"
			Leaving: "ease-in-out duration-300"
			From: "opacity-100"
			To: "opacity-0"
		-->
		<div
			class="opacity-0 group-data-[active=true]/root:opacity-100 
				pointer-events-auto absolute left-full top-0 flex w-16 
				justify-center pt-5 ease-in-out duration-300">
			<button type="button" name="close" class="-m-2.5 p-2.5"
			@click="${() => this.toggleActive()}">
			<span class="sr-only">Close sidebar</span>
			<svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>
			</button>
		</div>

		<div class="relative flex grow flex-col gap-y-5 overflow-y-auto 
			bg-gray-900 pb-2 ring-1 ring-white/10">
			<div class="flex px-6 mb-6 h-16 shrink-0 items-center">
				<!-- https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500 -->
				<img class="h-8 w-auto" src="./resources/writery.png" alt="Writery">
				<h1 class="pl-4 text-2xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r font-serif text-gradient" ">
					Writery
				</h1>
			</div>
			<nav class="flex flex-1 flex-col px-6">
				<ul role="list" class="flex flex-col space-y-1 pointer-events-auto">

					${this.navItems.map((item) => html`
						<li
							id="${item.name}"
							@click="${e => this.navItemClick(e, item)}"
						>
							<!-- Current: "bg-gray-800 text-white", Default: "text-gray-400 hover:text-white hover:bg-gray-800" -->
							<a
								href="#"
								class="bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
								>
								<div class="h-6 w-6 shrink-0">${unsafeSVG(item.icon)}</div>
								${item.title}
							</a>
						</li>`,
					)}

				</ul>
			</nav>
			<!-- App Navigation -->
			<nav class="pointer-events-auto absolute bottom-0 w-full px-6 ">

				<ul role="list" class="flex flex-col space-y-1">
					${this.navItems.map((item) => html`
						<li
							@click="${e => this.navItemClick(e, item)}"
						>
						<!-- Current: "bg-gray-800 text-white", Default: "text-gray-400 hover:text-white hover:bg-gray-800" -->
						<a
							href="#"
							class="bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
						>
							<div class="h-6 w-6 shrink-0">${unsafeSVG(item.icon)}</div>
							${item.title}
						</a>
						</li>`,
					)}
				</ul>

				<hr class="mt-6 border-t border-gray-700" />
				
				<div class="h-9 flex justify-evenly items-center">
					<p class="text-white text-sm">v${version}</p>
				</div>
			</nav>
		</div>
	</div>
</div>
</div>
</div>

<!-- Desktop: Static sidebar for desktop -->
<div class="hidden lg:block fixed inset-y-0 left-0 z-40 w-20 overflow-y-auto bg-gray-900 pb-4">
	<div class="flex h-16 shrink-0 items-center justify-center">
		<img class="h-8 w-auto" src="./resources/writery.png" alt="Writery">
	</div>
	<hr class="mb-6 border-t border-gray-700" />
	<!-- Desktop: App Navigation -->
	<nav>
		<ul role="list" class="flex flex-col items-center space-y-1">
			${this.navItems.map(
			(item) =>
				html`<li @click="${e => this.navItemClick(e, item)}">
		  <!-- Current: "bg-gray-800 text-white", Default: "text-gray-400 hover:text-white hover:bg-gray-800" -->
		  <a
			href="#"
			class="bg-gray-800 text-white group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold"
		  >
			<div class="h-6 w-6 shrink-0">${unsafeSVG(item.icon)}</div>
			<span class="sr-only">${item.title}</span>
		  </a>
		</li>`,
		)}
		</ul>
	</nav>
	<nav class="absolute bottom-0 text-center w-full">
		<hr class="mt-6 border-t border-gray-700" />
		<div class="h-14 flex justify-evenly items-center">
			<p class="text-white text-sm">v${version}</p>
		</div>
	</nav>
</div>

<!-- Mobile: App Bar + Nav -->
<!-- <div class="lg:hidden sticky px-4 py-4 top-0 z-40 flex items-center gap-x-6 bg-gray-50 dark:bg-gray-900 shadow-sm "> -->
<div class="lg:hidden fixed z-30 w-full px-4 h-16 flex items-center gap-x-6 bg-gray-50 dark:bg-gray-900 shadow-sm">

	<button type="button" class="-m-2.5 p-2.5 text-gray-400">
		<span class="sr-only">Open sidebar</span>
		<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
		<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
		</svg>
	</button>
	<div class="w-full text-center">
		<h1 class="text-2xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r font-serif text-gradient" ">
				Writery
		</h1>
	</div>
	<!-- <div class="flex-1 text-sm font-semibold leading-6 text-white">
		${this.navItems.find((x) => x.name === this.view)?.title}
	</div> -->
	<a href="#">
		<span class="sr-only">Not logged in</span>
			<span class="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
			<!-- <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
				<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
			</svg> -->
			<img class="h-full w-full" src="./resources/writery.png" alt="User">
		</span>
	</a>
</div>

<div class="flex flex-col h-screen">
	<!-- Main content area -->
	<main class="lg:pl-20 flex-1 w-full p-gradient mt-16 lg:mt-0">
		<toast-ui></toast-ui>
		<div class="h-[calc(100vh-4rem)] lg:h-screen">
			<slot name="content"></slot>
		</div>
	</main>
</div>

		`;
	}
}

declare global {
  interface HTMLElementTagNameMap {
    "app-layout": AppLayout;
  }
}

/* 
<!-- <registration-split></registration-split> -->

<!-- 

<ul role="list" class="flex flex-col items-center space-y-1 mb-6">
	${this.navItems.map((item) => html`
		<li @click="${e => this.navItemClick(e, item)}">
			<!-- Current: "bg-gray-800 text-white", Default: "text-gray-400 hover:text-white hover:bg-gray-800" -->
			<a
				href="#"
				class="bg-gray-800 text-white group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold"
			>
				<div class="h-auto w-7 shrink-0">${unsafeSVG(item.icon)}</div>
				<span class="sr-only">${item.title}</span>
			</a>
		</li>`,
	)}
</ul>	


<div class="bg-violet-900/80 shadow sm:rounded-lg">
<div class="px-4 py-5 sm:p-6">
	<h1 class="text-5xl w-fit p-4 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-pink-300" ">
		Writery
	</h1>

	<p class="mb-6 w-fit p-4 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48">
		A creative writing space
	</p>
	
	

	<br class="mb-4"/>
	<div class="flex justify-center text-white">
	Writery@__version__
	</div>
</div>
</div> -->
*/
import { LitElement } from 'lit-element';
import { firstCharUpperCase } from '../util/util.js';
type Constructor<T = {}> = new (...args: any[]) => T;


export declare class BaseElementInterface {
	highlight: boolean;
	protected renderHighlight(): 
	unknown;
}

export const BaseElement = <T extends Constructor<LitElement>>(superClass: T) => {

	class BaseElement extends superClass {
 	  	protected toast(message: string, variant: string, title: string) {
			this.dispatchEvent(new CustomEvent('toast', {
				bubbles: true,
				composed: true,
				detail: {
					variant: variant,
					message: message,
					title: title || firstCharUpperCase(variant),
				}
		   	}));
	   	}
		protected sendRefreshEvent(detail: RefreshEventDetail) {
			this.dispatchEvent(new CustomEvent('refresh', {
				bubbles: true,
				composed: true,
				detail,
		   	}));
	   	}
		protected errorFirstPromise(promise: Promise<any>) {
			return promise.then((result) => [null, result]).catch((error) => [error, null]);
		}
	};
	// Cast return type to your mixin's interface intersected with the superClass type
	// @ts-ignore
	return BaseElement as Constructor<BaseElementInterface> & T;
}

export const HOUR = 3600000;//60*60*1000

export function hasBeenHour(time) {
    const now = new Date().getTime();
    const diff = now - time;
    return diff > HOUR;
}

export function errorFirstPromise(promise) {
    return promise
        .then(data => [null, data])
        .catch(err => [err, null]);
}

/**
 * Chunk an array to smaller arrays
 * @param {Array} arr array to chunk
 * @param {Number} n items per chunk
 * @return {Iterator} iterator of chunks
 */
export function* chunk(arr, n) {
    for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n);
    }
}

export const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return (
        s4() +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        s4() +
        s4()
    );
}


/**
 * return a object url from a blob
 * @param {Blob} blob
 * @returns {String} object url
 */
export function getBlobUrl(blob) {
    const Url = window.URL || window.webkitURL;
    const url = Url.createObjectURL(blob);
    setTimeout(() => Url.revokeObjectURL(url), 1000);
    return url;
}

export function firstCharUpperCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toast(context: HTMLElement, message: string, variant: string, title?: string) {
    return () => context.dispatchEvent(new CustomEvent('toast', {
        bubbles: true,
        composed: true,
        detail: {
          variant: variant,
          message: message,
          title: title || firstCharUpperCase(variant),
        }
    }));
}

const _version = () => {
    //@ts-ignore
    return __version__;
}
export const version = _version();

export const onlineEvent: CustomEvent = new CustomEvent('online', {
    detail: {
        message: 'You are back online',
        variant: 'info',
    }
});
export const offlineEvent: CustomEvent = new CustomEvent('offline', {
    detail: {
        message: 'You are off line',
        variant: 'warn',
    }
});
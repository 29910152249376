import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";

import { TWStyles } from "../artifacts/twlit.js";
import { appNavItems } from "./util/config.js";
import { User } from "./util/aws.js";
import {
	getWriteryListItems,
} from "./util/writery.js";
import { refreshUser } from "./elements/aws-auth/cognito.js";
import { getUser, setUser } from "./util/idb.js";
import { getItems } from "./util/idb.js";
import {
	errorFirstPromise,
	onlineEvent,
	offlineEvent,
} from "./util/util.js";

import "./elements/view-home.js";
import "./elements/toast.js";
import "./elements/app-registration.js";
import "./elements/app-layout.js";

@customElement("writery-app")
class WriteryApp extends LitElement {
	static styles = [
		TWStyles,
		css`
			div > svg {
				fill: currentColor;
			}
			.p-gradient {
				background: linear-gradient(90deg, #ff9a9e 0%, #fad0c4 100%);
			}
    	`,
	];

	static properties = {
		view: { type: String },
	};

	view: string = "view-home";

	@state() writings: any[] = [];

	@state() navItems: NavItem[] = [];
	@state() user: User;

	appNavItems: NavItem[] = appNavItems;

	constructor() {
		super();
	}

	connectedCallback() {
		super.connectedCallback();
		this._initializeApp();
	}

	render() {
		return this.getHtml();
	}

	private async _initializeApp() {
		console.log("App: initializing...");
		this.view = "view-home";
		this.refreshCheck();
	}

	addListeners() {
		window.addEventListener('online', () => this.toastMessage(onlineEvent));
		window.addEventListener('offline', () => this.toastMessage(offlineEvent));
	}

	async getWritings(user: User) {
		console.log("App: getWritings", user);
		//@ts-ignore
		const call = getWriteryListItems(user);
		const [error, writings] = await errorFirstPromise(call);
		if (error) {
			console.warn(error);
			const writings = await getItems("writings");
			console.log("App: getWritings", writings);
			// sort by modified date (mod) newest first
			this.writings = this.sortItems(writings);
			return;
		}
		console.log("App: getWritings", writings);
		this.writings = this.sortItems(writings);
	}

	toastMessage(e: CustomEvent) {
		console.log('APP: toastMessage', e.detail);
		const el = this.renderRoot.querySelector('toast-ui') as ToastUi;
		console.log('APP: el', el);
		el.toast = e.detail;
	}

	auth(e: CustomEvent) {
		console.log("APP: auth", e.detail);
		this.user = e.detail;
		setUser(this.user);
		this.getWritings(this.user);
	}

	async refresh(e: CustomEvent) {
		console.log("APP: refresh", e.detail);
		const { 
			type,
			location,
			db,
			id,	
		} = e.detail as RefreshEventDetail;
		if(type === 'data' && location === 'local') {
			const writings = await getItems(db);
			this.writings = this.sortItems(writings);
		}
	}

	sortItems(items: WriteryListItem[]) {
		return items.sort((a, b) => b.mod - a.mod);
	}

	async refreshCheck() {
		console.log('APP: user check');
		const uid = localStorage.getItem('uid');
		const user = await getUser(uid);
		if (user) {
			const freshUser = await refreshUser(user);
			console.log('APP: freshUser', freshUser);
			this.user = freshUser;
			this.getWritings(freshUser);
		}
	}

	getView() {
		if (this.user) return html`<view-home .writings=${this.writings} .user=${this.user} class="h-full w-full"></view-home>`;/* opacity-100 transition-opacity ease-linear duration-300 */
		return html`<app-registration class="pt-10 h-full w-full"></app-registration>`;
	}

	getHtml() {
		return html`
			<div 
				class="h-dvh" 
				@auth=${this.auth}
				@refresh=${this.refresh}
				@toast=${this.toastMessage}
				@menu=${(e: CustomEvent) => this.renderRoot.querySelector('app-layout').toggleIfNeed(e.detail)}>

				<toast-ui></toast-ui>
			
				<app-layout .navItems=${this.appNavItems} .view=${this.view}>
					<div slot="content">
						${this.getView()}
					</div>
				</app-layout>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"writery-app": WriteryApp;
	}
}

import { CSSResult, html } from "lit";
import { customElement, property, state } from 'lit/decorators.js';// @ts-ignore
import { TWStyles } from "../../../artifacts/twlit.js";
import { forgotPasswordConfirmation, } from './cognito.js';// @ts-ignore
import { errorFirstPromise, AuthExtension } from './auth-utils.js';

@customElement('aws-auth-forgot-confirm')
export class AwsAuthForgotConfirm extends AuthExtension {

	static styles: CSSResult[] = [TWStyles as CSSResult];

	@property() email: string = '';
	@state() pass: string = '';
	@state() passAgain: string = '';
	@state() confirmationCode: string = '';

  async verifyPasswordCode(e: Event) {
    e.preventDefault();
    if(!this.email || !this.confirmationCode) return this.toast({ variant: 'error', title: 'Forgot Password Error', message: 'Email and code are required' });
    if(!this.pass || !this.passAgain) return this.toast({ variant: 'error', title: 'Forgot Password Error', message: 'Password and confirmation are required' });
    if(this.pass !== this.passAgain) return this.toast({ variant: 'error', title: 'Forgot Password Error', message: 'Passwords do not match' });

    const call = forgotPasswordConfirmation({
      username: this.email,
      code: this.confirmationCode,
      password: this.pass
    });
    
    const [err, res] = await errorFirstPromise(call);

    if(err) {
      console.error(err);
      this.dispatchEvent(new CustomEvent('toast', {
        bubbles: true,
        composed: true,
        detail: {
          variant: 'error',
          title: 'Forgot Password Error',
          message: err.message
        }
      }));
      return;
    }

    console.log(res);

    this.toast({ variant: 'success', title: 'Forgot Password', message: 'Password reset' });

    this.show('login');
  }
  
	render() {
		return html`
      <form class="space-y-6" @submit=${this.verifyPasswordCode}>
        <p class="text-black dark:text-white">
          A code has been sent to your email. Please enter it below and set a new password.
        </p>
        <div>
          <label for="code" class="block text-sm font-medium leading-6 text-black  dark:text-white">
            Code
          </label>
          <div class="mt-2">
            <input @change=${(e: Event) => this.confirmationCode = (e.target as HTMLInputElement).value} id="code" name="code" type="text" required class="block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm leading-6 text-white">
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium leading-6 text-black dark:text-white">
            Password
          </label>
          <div class="mt-2">
            <input @change=${(e: Event) => this.pass = (e.target as HTMLInputElement).value} id="password" name="password" type="password" autocomplete="current-password" required 
              class="block w-full rounded-md border-0  py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 text-black">
          </div>
        </div>

        <div>
          <label for="password-again" class="block text-sm font-medium leading-6 text-black dark:text-white">
            Re-enter Password
          </label>
          <div class="mt-2">
            <input @change=${(e: Event) => this.passAgain = (e.target as HTMLInputElement).value} id="password-again" name="password-again" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0   py-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 text-black">
          </div>
        </div>

        <div>
          <button class="bg-primary color-primary flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
            Confirm
          </button>
        </div>
      </form>
    `;
  }
}
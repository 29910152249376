import { CSSResult, html } from "lit";
import { customElement, property, state } from 'lit/decorators.js';// @ts-ignore
import { TWStyles } from "../../../artifacts/twlit.js";
import { signIn, } from './cognito.js';// @ts-ignore
import { errorFirstPromise, AuthExtension, isEmail } from './auth-utils.js';

@customElement('aws-auth-login')
export class AwsAuthLogin extends AuthExtension {

	static styles: CSSResult[] = [TWStyles as CSSResult];

	@property() email: string = '';
	@state() pass: string = '';

  get creds() {
    return {
      Username: this.email,
      Password: this.pass,
    }
  }

  async submit(e: Event) {
    e.preventDefault();

    if(!this.email || !this.pass) return this.toast({
      message: 'Needs an email and password',
      variant: 'error',
      title: 'Sign In Error',
    });

    if(!isEmail(this.email)) return this.toast({
      message: 'Email is not valid',
      variant: 'error',
      title: 'Sign In Error',
    });

    const call = signIn(this.creds);

    const [err, user] = await errorFirstPromise(call);
    
    if(err) {
      console.error(err);
      return this.dispatchEvent(new CustomEvent('toast', {
        bubbles: true,
        composed: true,
        detail: {
          variant: 'error',
          title: 'Sign In Error',
          message: err.message
        }
      }));
    }

    console.log(user);

    return this.dispatchEvent(new CustomEvent('auth', {
      bubbles: true,
      composed: true,
      detail: user
    }));
  }

	render() {
		return html`

      <form class="space-y-6" @submit=${this.submit}>

        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-black dark:text-white">Email address</label>
          <div class="mt-2">
            <input 
              @change=${(e: Event) => this.email = (e.target as HTMLInputElement).value} value=${this.email} 
              id="email" 
              name="email" 
              type="text" 
              required 
              class="block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              autocomplete="username"/>
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-black dark:text-white">Password</label>
            <div class="text-sm">
              <a @click=${() => this.show('forgot')} class="font-semibold cursor-pointer color-primary">Forgot password?</a>
            </div>
          </div>
          <div class="mt-2">
            <input @change=${(e: Event) => this.pass = (e.target as HTMLInputElement).value} 
            id="password" 
            name="password" 
            type="password" 
            autocomplete="current-password" 
            required 
            class="block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm leading-6">
            </div>
        </div>

        <div>
            <button class="bg-primary text-white flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
              Sign In
            </button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-black dark:text-white">
        Have a code?&nbsp;
        <a @click=${() => this.show('code-verify')} class="cursor-pointer font-semibold leading-6 color-primary">
          Confirm
        </a>
      </p>

      <p class="mt-2 text-center text-sm text-black  dark:text-white">
        Don't have an account yet?&nbsp;
        <a @click=${() => this.show('sign-up')} class=" cursor-pointer font-semibold leading-6 color-primary">
          Create Account
        </a>
      </p>
    `;
  }
}

//import { DynamoDB } from "@aws-sdk/client-dynamodb";
import { DynamoDBClient, QueryCommand, PutItemCommand } from "@aws-sdk/client-dynamodb";
import { fromCognitoIdentityPool, } from "@aws-sdk/credential-providers";
import { CognitoIdentityClient, } from "@aws-sdk/client-cognito-identity";
import { setItem } from "./idb.js";

import { guid } from "./util.js";

function getClient(user){
    const dynamoDBClient = new DynamoDBClient({
        region: user.region,
        credentials: fromCognitoIdentityPool({
            //@ts-ignore
            client: new CognitoIdentityClient({ region: user.region }),
            identityPoolId: user.identityPoolId,
            logins: {
                [`cognito-idp.${user.region}.amazonaws.com/${user.userPoolId}`]: user.session.IdToken,
            },
        }),
    });

    return dynamoDBClient;
}

const remoteToLocalItem = item => ({
    id: item.id.S,
    content: item.content.S,
    userId: item.userId.S,
    title: item.title.S,
});


export async function getUserListItems(user) {

	try {
 
        const dynamoDBClient = getClient(user);

        const params = {
            TableName: "user-writings",
            KeyConditionExpression: "userId = :userId",
            ExpressionAttributeValues: {
                ":userId": { S: user.id },
            },
        };
    
        const command = new QueryCommand(params);
        const data = await dynamoDBClient.send(command);
        return data.Items.map(remoteToLocalItem);

	} catch (e) {
	  console.warn(e);
	  return e;
	}
}

export async function saveUserListItem(user: User, item: WriteryListItem) {
    
    try {

    const dynamoDBClient = getClient(user);

    console.log('saveUserListItem => ', item);

    const Item = {
        userId: { S: user.id },
        id: { S: item.id },
        title: { S: item.title },
        content: { S: item.content },
        mod: { N: item.mod.toString() },
    }

    const params = {
        TableName: "user-writings",
        Item,
    };

    const command = new PutItemCommand(params);

    const data = await dynamoDBClient.send(command);

    return {
        data,
        item: remoteToLocalItem(Item),
    };

    }
    catch(e) {
        console.warn(e);
        return e;
    }
}
import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { TWStyles } from "../../artifacts/twlit.js";
import { BaseElement } from "./BaseElement.js";
import { saveWriteryListItem } from "../util/writery.js";
import { guid } from "../util/util.js";

@customElement("new-writing")
class NewWriting extends BaseElement(LitElement) {

    static styles = [TWStyles, css`
		.p-gradient {
		background: linear-gradient(90deg, #ff9a9e 0%, #fad0c4 100%);
		}
		.color-writery {
			color: #ff9a9e;
		}
		.bg-writery {
			background-color: #ff9a9e;
		}
  	`];
	
	@property({type:Object}) user: User;
  	@state() isOpen: Boolean = false;
  	@state() writing: WriteryListItem = {} as WriteryListItem; 

	get title(){
		return this.writing?.title || '';
	}
	set title(value){
		this.writing.title = value;
	}
	get content(){
		return this.writing?.content || '';
	}
	set content(value){
		this.writing.content = value;
	}
	get id(){
		return this.writing?.id || null;
	}
	get parentClass(){
		return this.isOpen ? 'block' : "hidden";
	}

	open(writing = null){
		console.log('writing => ', writing);
		this.writing = writing;
		this.isOpen = true;
	}
	
	close(){
		this.isOpen = false;
	}

	async saveWriting(){
		
		try {

			const title = (this.shadowRoot?.querySelector('input[name="title"]') as HTMLInputElement).value;
			const content = (this.shadowRoot?.querySelector('textarea[name="content"]') as HTMLTextAreaElement).value;
			console.log({
				title,
				content,
			})

			const item: WriteryListItem = {
				title,
				content,
				userId: this.user.id,
				id: this.id ? this.id : guid(),
				mod: new Date().getTime(),
			}

			console.log('saveWriting item => ', item);

			await saveWriteryListItem(this.user, item);
			// @ts-ignore
			this.toast('Created successfully!', 'success')
			// @ts-ignore
			this.sendRefreshEvent({
				type: 'data',
				location: 'local',
				db: 'user-writings',
				id: item.id,
			})
			
			this.close()

			this.dispatchEvent(new CustomEvent('saved', { composed: true, bubbles: true }));
			
		} catch (error) {

			console.error(error);
			// @ts-ignore
			return this.toast(error.message, 'error')
		}
	}

	handleKeydown(event: KeyboardEvent) {
		if (event.ctrlKey && event.key === 's') {
			event.preventDefault();
			this.saveWriting();
		}
	}

	handleInputChange(event: Event) {
		const target = event.target as HTMLInputElement | HTMLTextAreaElement;
		this[target.name] = target.value;
	}

  render() {
    return html`
<div class="w-full">
<form 
action="#" 
class="bg-transparent fixed bottom-0 w-full lg:w-2/3 xl:w-1/2 right-0 m-0 ${this.parentClass}">
  <div class="overflow-hidden rounded-lg border border-gray-300 shadow-sm 
  	focus-within:border-red-200 focus-within:ring-1 focus-within:ring-red-200">
    <label for="title" class="sr-only">Title</label>
    <input 
		.value=${this.title}
		type="text" 
		name="title" 
		id="title" 
		placeholder="Title"
		@change=${this.handleInputChange}
		class="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0">
    <label for="content" class="sr-only">Body</label>
    <textarea 
		.value=${this.content}
		rows="15" 
		name="content" 
		id="content" 
		placeholder="Write on..."
		@change=${this.handleInputChange}
		class="block w-full resize-none border-0 py-0 text-gray-900 
			placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
		@keydown=${this.handleKeydown}>
	</textarea>

    <!-- Spacer element to match the height of the toolbar -->
    <div aria-hidden="true" class="bg-white">
      <div class="py-2">
        <div class="h-9"></div>
      </div>
      <div class="h-px"></div>
      <div class="py-2">
        <div class="py-px">
          <div class="h-9"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="absolute inset-x-px bottom-0 bg-white ">
    <div class="flex items-center justify-between space-x-3 border-t 
		border-gray-200 px-2 py-2 sm:px-3">
      	<div class="flex">
			<label class="relative inline-flex cursor-pointer items-center">
				<input type="checkbox" class="peer sr-only" />
				<div
					class="flex peer h-8 items-center gap-4 rounded-full 
						text-xs  
						text-center 
						text-black
						bg-white
						px-3 

						after:absolute 
						after:left-1 
						after:h-6
						after:w-16 
						after:rounded-full 		
						after:bg-red-300/40
						after:transition-all 
						after:content-[''] 

						peer-checked:text-white 
						peer-checked:bg-red-300
						peer-checked:after:bg-white/40
						peer-checked:after:translate-x-full 
						peer-focus:outline-none">
					<span class=" w-12">Private</span>
					<span class=" w-12">Public</span>
				</div>
			</label>
			<!-- 
					class="peer flex h-8 items-center gap-4 rounded-full bg-orange-600 px-3 after:absolute after:h-6 after:w-16 after:rounded-full 
					after:bg-white/40 after:transition-all after:content-[''] peer-checked:bg-stone-600 peer-checked:after:translate-x-full peer-focus:outline-none dark:border-slate-600 dark:bg-slate-700 text-sm text-white"
	 -->
		</div>
      	<div class="flex-shrink-0">
			<button 
				@click=${this.close} 
				type="button" 
				class="color-writery mr-4 bg-white inline-flex items-center rounded-md 
					px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline 
					focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
				Cancel
			</button>
			
			<button 
				@click=${this.saveWriting} 
				type="button" 
				class="bg-writery text-white inline-flex items-center rounded-md 
					px-3 py-2 text-sm font-semibold shadow-sm 
					focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
					focus-visible:outline-indigo-600">
				Save
			</button>
		</div>
    </div>
</div>
</form>
</div>
	`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "new-writing": NewWriting;
  }
}

/* 
<!-- <registration-split></registration-split> -->

<!-- <div class="bg-violet-900/80 shadow sm:rounded-lg">
<div class="px-4 py-5 sm:p-6">
	<h1 class="text-5xl w-fit p-4 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-pink-300" ">
		Writery
	</h1>

	<p class="mb-6 w-fit p-4 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48">
		A creative writing space
	</p>
	
	

	<br class="mb-4"/>
	<div class="flex justify-center text-white">
	Writery@__version__
	</div>
</div>
</div> -->
*/
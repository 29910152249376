import {
	CognitoIdentityProviderClient,
	SignUpCommand,
	ConfirmSignUpCommand,
	InitiateAuthCommand,
	ForgotPasswordCommand,
	ConfirmForgotPasswordCommand,
	AuthFlowType,
} from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import
//import AWS from 'aws-sdk'
import { CognitoIdentityClient, GetIdCommand } from "@aws-sdk/client-cognito-identity";
import {
	Region,
	ClientId,
	UserPoolId,
	cogIdConfig,
	User,
} from "./../../util/aws.js";
import { jwtDecode } from "jwt-decode";
import { setItem } from "./../../util/idb.js";


export async function signIn({ Username, Password, }) {

	const client = new CognitoIdentityProviderClient(cogIdConfig);

	// sign in user
	const command = new InitiateAuthCommand( {
		AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
		ClientId,
		AuthParameters: {
			USERNAME: Username,
			PASSWORD: Password,
		}
	} );

	const init = await client.send(command);

	console.log('signIn: ', init);

    const idToken = init.AuthenticationResult.IdToken;

    // Decode the IdToken to get the user ID (sub)
    const decodedToken = jwtDecode(idToken);

	const user = new User(decodedToken, init.AuthenticationResult);

	setItem('user', user);

	return user;
}




export function signUp(input) {

	const client = new CognitoIdentityProviderClient(cogIdConfig);

	const command = new SignUpCommand({
		//@ts-ignore
		Region,
		ClientId,
		...input,
	});

	return client.send(command);
}

export function verifyEmail({ username, code }) {

	const client = new CognitoIdentityProviderClient(cogIdConfig);

	const command = new ConfirmSignUpCommand({
		ClientId, // required
		Username: username, // required
		ConfirmationCode: code, // required
	});

	return client.send(command);
}

export function forgotPassword({ username }) {

	const client = new CognitoIdentityProviderClient(cogIdConfig);

	const command = new ForgotPasswordCommand({
		ClientId, // required
		Username: username, // required
	});

	return client.send(command);
}

export function forgotPasswordConfirmation({ username, password, code }) {

	const client = new CognitoIdentityProviderClient(cogIdConfig);

	const command = new ConfirmForgotPasswordCommand({
		ClientId, // required
		Username: username, // required
		ConfirmationCode: code, // required
		Password: password, // required
	});

	return client.send(command);
}

export async function refreshUser(user: User) {

	const client = new CognitoIdentityProviderClient(cogIdConfig);

	const command = new InitiateAuthCommand({
		AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
		ClientId,
		AuthParameters: {
			REFRESH_TOKEN: user.session.RefreshToken,
			identityPoolId: user.identityPoolId,
			region: user.region,
			userPoolId: user.userPoolId,
		},
	});

	const result = await client.send(command);

	user.session = {...user.session, ...result.AuthenticationResult};

	return user;
}
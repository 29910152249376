import { LitElement, css, html } from "lit";
import { customElement, property, state } from 'lit/decorators.js';
import { TWStyles } from "../../artifacts/twlit.js";


@customElement('toast-ui')
export class ToastUi extends LitElement {

	static styles = [
    TWStyles,
  ];

  /**
   * The variant of the toast
   * @type {string}
   * @attr
   * @default info
   * @values info, warn, error, success
   */
	@state() variant = 'success';
	@state() message = 'Hello World';
	@state() title = 'Success';
	@state() messages = [];
	@state() isActive = false;
  @property({ type: Object }) 
  set toast(data: Toast) {
    const {variant, message, messages, title} = data;
    this.variant = variant;
    this.message = message;
    this.messages = messages;
    this.title = title;
    this.transition();
  }

  get icon() {
    switch(this.variant) {
      case 'error':
        return this.errorIcon;
      case 'warn':
        return this.warnIcon;
      case 'success':
        return this.successIcon;
      default:
        return this.infoIcon;
    }
  }
  getColor(pre) {
    switch(this.variant) {
      case 'error':
        return `${pre}-error`;
      case 'warn':
        return `${pre}-warn`;
      case 'success':
        return `${pre}-accent`;
      default:
        return `${pre}-primary`;
    }
  }

  get errorIcon() {
    return html`<svg class=" text-red-400 h-5 w-5 text-error fill-current" viewBox="0 0 20 20"  aria-hidden="true">
      <path class="fill-current" fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
    </svg>`;
  }
  get warnIcon() {
    return html`<svg class=" text-orange-400 h-5 w-5 text-warn" viewBox="0 0 20 20"  aria-hidden="true">
    <path class="fill-current" fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
  </svg>`;
  }
  get successIcon() {
    return html`<svg class=" text-green-400 h-5 w-5 text-accent}" viewBox="0 0 20 20"  aria-hidden="true">
    <path class="fill-current" fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
  </svg>`;
  }
  get infoIcon() {
    return html`<svg class=" text-blue-500 h-5 w-5 text-primary fill-current" viewBox="0 0 20 20"  aria-hidden="true">
    <path class="fill-current" fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
  </svg>`;
  }
  enterClasses = ' z-50 relative transform ease-out duration-300 transition translate-y-2 sm:translate-y-0 sm:translate-x-2 opacity-100';
  leaveClasses = 'fixed transition ease-in duration-100 translate-y-0 sm:translate-x-0 opacity-0';


  transition(){
    this.isActive = true;
    // Hide the toast message after 3 seconds
    setTimeout(() => {
      this.isActive = false;
    }, 4000);
  }

  get aniClassList() {
    return this.isActive ? this.enterClasses : this.leaveClasses;
  }

	render() {
		return html`<!-- Global notification live region, render this permanently at the end of the document -->
    <div id="toast" class=${this.aniClassList}>
      <div class="flex w-full flex-col items-center space-y-4 lg:items-end">
        <!--
          Notification panel, dynamically insert this into the live region when it needs to be displayed
    
          Entering: "transform ease-out duration-300 transition"
            From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            To: "translate-y-0 opacity-100 sm:translate-x-0"
          Leaving: "transition ease-in duration-100"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="mt-8 lg:mr-8 lg:mt-4 absolute z-50 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg 
          bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                ${this.icon}
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">${this.title}</p>
                <p class="mt-1 text-sm text-gray-500">${this.message}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button @click=${() => this.isActive = !this.isActive} type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`
  }
}

/* 
<svg class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

    <div id="toast" class="mt-12 ease-in-out duration-500 translate-y-[-100%] opacity-0 hidden ">
    <div class=" max-w-md rounded-md bg-white-light p-4 my-0 mx-auto">
  <div class="flex">
    <div class="flex-shrink-0">
      
    </div>
    <div class="ml-3">
      <h3 class="text-sm font-medium text-white-light}">
        ${this.title ? this.title : this.variant.slice(0, 1).toUpperCase() + this.variant.slice(1)}
      </h3>
      <div class="mt-2 text-sm text-white-light}">
        <p>${this.message}</p>
      </div>
    </div>
  </div>
  </div>
</div> 


	render() {
		return html`<div id="toast" class="6rounded-md bg-red-50 p-4 translate-y-[-100%] opacity-0">
    <div class="flex">
      <div class="flex-shrink-0">
        
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">There were 2 errors with your submission</h3>
        <div class="mt-2 text-sm text-red-700">
          <ul role="list" class="list-disc space-y-1 pl-5">
            ${this.messages?.length ? this.messages.map((msg) => html`<li>${msg}</li>`) : html`<li>${this.message}</li>`}
          </ul>
        </div>
      </div>
    </div>
  </div>`
    } 
    
<div id="toast" class="6rounded-md bg-red-50 p-4 translate-y-[-100%] opacity-0">
    <div class="flex">
      <div class="flex-shrink-0">
        
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">There were 2 errors with your submission</h3>
        <div class="mt-2 text-sm text-red-700">
          <ul role="list" class="list-disc space-y-1 pl-5">
            ${this.messages?.length ? this.messages.map((msg) => html`<li>${msg}</li>`) : html`<li>${this.message}</li>`}
          </ul>
        </div>
      </div>
    </div>
  </div>
  */
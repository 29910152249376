import { LitElement } from 'lit-element';
type Constructor<T = {}> = new (...args: any[]) => T;


export declare class SwipeMixinInterface {
	highlight: boolean;
	protected renderHighlight(): 
	unknown;
}

export const SwipeMixin = <T extends Constructor<LitElement>>(superClass: T) => {

	class SwipeMixin extends superClass {
		__xDown;
		__yDown;
		
		__ignoreSwipe(event: TouchEvent) {
			// if some touches come from elements with ignoreswipe class > ignore
			return Array.from(event.touches).some((t) =>
				(t.target as HTMLElement).classList.contains('noswipe')
			);
		}

		__handleTouchStart(event) {
			if (this.__ignoreSwipe(event)) {
				this.__xDown = undefined;
				this.__yDown = undefined;
				return;
			}

			const firstTouch = event.touches[0];
			this.__xDown = firstTouch.clientX;
			this.__yDown = firstTouch.clientY;
		}

		__handleTouchMove(event: TouchEvent, funcNames: any) {

			if (!this.__xDown || !this.__yDown) {
				return;
			}

			const xUp = event.touches[0].clientX;
			const yUp = event.touches[0].clientY;

			const xDiff = this.__xDown - xUp;
			const yDiff = this.__yDown - yUp;

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				/*most significant*/
				if (xDiff > 0) {
					/* left swipe */
					console.log('app: left swipe ', true);
					if(funcNames.left && typeof this[funcNames.left] === 'function'){
						this[funcNames.left]();
					}
				} else {
					/* right swipe */
					console.log('app: right swipe ', true);
					if(funcNames.right && typeof this[funcNames.right] === 'function'){
						this[funcNames.right]();
					}
				}
			} else {
				if (yDiff > 0) {
					/* up swipe */
					console.log('app: up swipe ', true);
					if(funcNames.up && typeof this[funcNames.up] === 'function'){
						this[funcNames.up]();
					}
				} else {
					/* down swipe */
					console.log('app: down swipe ', true);
					if(funcNames.down && typeof this[funcNames.down] === 'function'){
						this[funcNames.down]();
					}
				}
			}

			/* reset values */
			this.__xDown = null;
			this.__yDown = null;
		}
	};
	// Cast return type to your mixin's interface intersected with the superClass type
	// @ts-ignore
	return SwipeMixin as Constructor<SwipeMixinInterface> & T;
}